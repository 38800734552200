import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout/Layout";
import Modules from "../components/Modules/Modules";
import BlockContent from "../components/BlockContent/BlockContent";
import "../styles/base.scss";
import "./page.scss";

const PageTemplate = ({ data, errors, location }) => {
    if (errors) return <Layout errors={errors} />;

    const { page } = data;
    const href = location.href;

    // const seoPageData = {
    //     title: page.pageContent.seo?.title || page.title,
    //     description: page.pageContent.seo?.description,
    //     keywords: page.pageContent.seo?.keywords,
    //     noIndex: page.pageContent.seo?.noindex,
    //     data: page.pageContent.seo?.data,
    //     altCanonical: altCanonical
    // };

    const {
        description: seoDescription,
        keywords: seoKeywords,
        noindex: seoNoIndex,
        title: seoTitle,
        data: seoData,
        altCanonical,
    } = page.pageContent.seo;

    const seoPageData = {
        title: seoTitle ? seoTitle : page.title,
        description: seoDescription ? seoDescription : "",
        keywords: seoKeywords ? seoKeywords : null,
        noIndex: seoNoIndex ? seoNoIndex : false,
        data: seoData ? seoData : false,
        altCanonical: altCanonical,
        href: href ? href : "",
    };

    return (
        <Layout
            seoData={seoPageData}
            heroContent={page.pageContent.hero}
            pageHeading={page.pageContent.pageHeading}
        >
            {page.pageContent && page.pageContent.pageHeading && (
                <div className="content-page-heading">
                    <BlockContent blocks={page.pageContent.pageHeading} />
                </div>
            )}
            {page.pageContent && page.pageContent.modules && (
                <Modules modulesData={page.pageContent.modules} />
            )}
        </Layout>
    );
};

export default PageTemplate;

export const query = graphql`
    query PageTemplateQuery($slug: String!) {
        page: sanityPage(slug: { current: { eq: $slug } }) {
            title: pageTitle
            slug {
                current
            }
            id
            pageContent {
                seo {
                    description
                    title
                    keywords
                    noindex
                    data
                    altCanonical
                }
                hero: heroBanner {
                    eyebrow
                    title: heroTitle {
                        style
                        _key
                        _type
                        children {
                            _type
                            _key
                            marks
                            text
                        }
                    }
                    marqueeText
                    marqueeImages {
                        ...ImageWithPreview
                        asset {
                            metadata {
                                dimensions {
                                    width
                                    height
                                }
                            }
                            filename
                            url
                        }
                    }
                    primaryImage {
                        ...ImageWithPreview
                    }
                    primaryalt
                    secondaryImage {
                        ...ImageWithPreview
                    }
                    secondaryalt
                    backdropImage {
                        ...ImageWithPreview
                    }
                    ctaButtonOne {
                        linkTo {
                            external
                            internal {
                                slug {
                                    current
                                }
                            }
                        }
                        title
                    }
                    ctaButtonTwo {
                        title
                        linkTo {
                            external
                            internal {
                                slug {
                                    current
                                }
                            }
                        }
                    }
                    subtext
                }
                modules {
                    ... on SanityCarousel {
                        _key
                        _type
                        backdropImage {
                            ...ImageWithPreview
                        }
                        text
                        title {
                            style
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        simpleLink {
                            title
                            external
                            internal {
                                slug {
                                    current
                                }
                            }
                        }
                        slides {
                            image {
                                ...ImageWithPreview
                                asset {
                                    metadata {
                                        dimensions {
                                            width
                                            height
                                        }
                                    }
                                    url
                                }
                            }
                            title
                            lineTwo
                            lineThree
                            lineOne
                        }
                    }
                    ... on SanityDemoForm {
                        _key
                        _type
                        showDemoForm
                    }
                    ... on SanityTestimonials {
                        _key
                        _type
                        testimonialList {
                            jobTitle
                            name
                            logoAlt
                            image {
                                ...ImageWithPreview
                            }
                            logo {
                                ...ImageWithPreview
                            }
                        }
                    }
                    ... on SanityFullTextBlock {
                        _key
                        _type
                        heading {
                            style
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        body {
                            style
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        internalLink
                        ctaText
                        ctaLink
                    }
                    ... on SanityHomeSteps {
                        _key
                        _type
                        title: stepsTitle {
                            style
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        stepsBulletTitle {
                            style
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        imageSizing
                        stepsList {
                            title
                            text
                            image {
                                ...ImageWithPreview
                            }
                            alt
                            icon {
                                ...ImageWithPreview
                            }
                            backdropImage {
                                ...ImageWithPreview
                            }
                            ctaText
                            ctaLink {
                                external
                                internal {
                                    slug {
                                        current
                                    }
                                }
                            }
                        }
                    }
                    ... on SanityStats {
                        backdropImage {
                            ...ImageWithPreview
                        }
                        _key
                        _type
                        title {
                            style
                            list
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        statOne {
                            text
                            number
                            icon {
                                ...ImageWithPreview
                            }
                        }
                        statThree {
                            text
                            number
                            icon {
                                ...ImageWithPreview
                            }
                        }
                        statTwo {
                            text
                            number
                            icon {
                                ...ImageWithPreview
                            }
                        }
                    }
                    ... on SanityAccordian {
                        _key
                        _type
                        title: accordianTitle {
                            style
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        list {
                            title
                            description {
                                style
                                _key
                                _type
                                children {
                                    _type
                                    _key
                                    text
                                }
                            }
                        }
                    }
                    ... on SanitySocialProofMarquee {
                        _key
                        _type
                        title: headingText {
                            style
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        useCustomLogos
                        useCustomHeading
                        socialProofImages {
                            ...ImageWithPreview
                            asset {
                                metadata {
                                    dimensions {
                                        width
                                        height
                                    }
                                }
                                url
                            }
                        }
                    }
                    ... on SanityCardGroup {
                        _key
                        _type
                        title: cardGroupTitle {
                            style
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        extraWide
                        cardList {
                            body {
                                style
                                _key
                                _type
                                children {
                                    _type
                                    _key
                                    marks
                                    text
                                }
                            }
                            modalBody {
                                style
                                _key
                                _type
                                children {
                                    _type
                                    _key
                                    marks
                                    text
                                }
                            }
                            ctaLink
                            ctaText
                            ctaOpensModal
                            internalLink
                            heading {
                                style
                                _key
                                _type
                                children {
                                    _type
                                    _key
                                    marks
                                    text
                                }
                            }
                            iconAlt
                            icon {
                                asset {
                                    _id
                                    _key
                                    url
                                    filename
                                    metadata {
                                        dimensions {
                                            aspectRatio
                                            height
                                            width
                                        }
                                    }
                                }
                            }
                            _type
                            showBoxShadow
                        }
                    }
                    ... on SanityTwoColumns {
                        _key
                        _type
                        swappable {
                            _type
                            _key
                            title: titleWithAccent {
                                style
                                _key
                                _type
                                children {
                                    _type
                                    _key
                                    marks
                                    text
                                }
                            }
                            columnOne {
                                columnTypes {
                                    ... on SanityColumnDetails {
                                        _key
                                        _type
                                        simpleLink {
                                            external
                                            internal {
                                                slug {
                                                    current
                                                }
                                            }
                                            title
                                        }
                                        text
                                        title: titleWithAccent {
                                            style
                                            _key
                                            _type
                                            children {
                                                _type
                                                _key
                                                marks
                                                text
                                            }
                                        }
                                    }
                                    ... on SanityColumnImages {
                                        _key
                                        _type
                                        image {
                                            ...ImageWithPreview
                                        }
                                        alt
                                        marginTop
                                        backdropImage {
                                            ...ImageWithPreview
                                        }
                                        larger
                                        marginTop
                                        popups {
                                            headline
                                            text
                                        }
                                    }
                                    ... on SanitySubLinks {
                                        _key
                                        _type
                                        subLinksList {
                                            icon {
                                                ...ImageWithPreview
                                            }
                                            simpleLink {
                                                external
                                                title
                                                internal {
                                                    slug {
                                                        current
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    ... on SanitySubList {
                                        _key
                                        _type
                                        heading {
                                            style
                                            _key
                                            _type
                                            children {
                                                _type
                                                _key
                                                marks
                                                text
                                            }
                                        }
                                        subCheckList {
                                            icon {
                                                ...ImageWithPreview
                                            }
                                            listText
                                        }
                                        subHeading
                                    }
                                    ... on SanitySubColumns {
                                        _key
                                        _type
                                        titleWithAccent {
                                            style
                                            _key
                                            _type
                                            children {
                                                _type
                                                _key
                                                marks
                                                text
                                            }
                                        }
                                        subColumnsList {
                                            title
                                            text
                                            image {
                                                ...ImageWithPreview
                                            }
                                        }
                                    }
                                }
                            }
                            columnTwo {
                                columnTypes {
                                    ... on SanityColumnDetails {
                                        _key
                                        _type
                                        simpleLink {
                                            external
                                            internal {
                                                slug {
                                                    current
                                                }
                                            }
                                            title
                                        }
                                        text
                                        title: titleWithAccent {
                                            style
                                            _key
                                            _type
                                            children {
                                                _type
                                                _key
                                                marks
                                                text
                                            }
                                        }
                                    }
                                    ... on SanityColumnImages {
                                        _key
                                        _type
                                        image {
                                            ...ImageWithPreview
                                        }
                                        alt
                                        marginTop
                                        backdropImage {
                                            ...ImageWithPreview
                                        }
                                        larger
                                        marginTop
                                        popups {
                                            headline
                                            text
                                        }
                                    }
                                    ... on SanitySubLinks {
                                        _key
                                        _type
                                        subLinksList {
                                            icon {
                                                ...ImageWithPreview
                                            }
                                            simpleLink {
                                                external
                                                title
                                                internal {
                                                    slug {
                                                        current
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    ... on SanitySubList {
                                        _key
                                        _type
                                        heading {
                                            style
                                            _key
                                            _type
                                            children {
                                                _type
                                                _key
                                                marks
                                                text
                                            }
                                        }
                                        subCheckList {
                                            icon {
                                                ...ImageWithPreview
                                            }
                                            listText
                                        }
                                        subHeading
                                    }
                                    ... on SanitySubColumns {
                                        _key
                                        _type
                                        titleWithAccent {
                                            style
                                            _key
                                            _type
                                            children {
                                                _type
                                                _key
                                                marks
                                                text
                                            }
                                        }
                                        subColumnsList {
                                            title
                                            text
                                            image {
                                                ...ImageWithPreview
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on SanityThreeColumns {
                        _key
                        _type
                        threeColumnOne {
                            text
                            title
                            image {
                                ...ImageWithPreview
                            }
                            backdropImage {
                                ...ImageWithPreview
                            }
                        }
                        threeColumnTwo {
                            text
                            title
                            image {
                                ...ImageWithPreview
                            }
                            backdropImage {
                                ...ImageWithPreview
                            }
                        }
                        threeColumnThree {
                            text
                            title
                            image {
                                ...ImageWithPreview
                            }
                            backdropImage {
                                ...ImageWithPreview
                            }
                        }
                    }
                }
                pageHeading {
                    style
                    _key
                    _type
                    children {
                        _type
                        _key
                        marks
                        text
                    }
                }
            }
        }
    }
`;
